import Head from 'next/head'

const MetaHeader = ({
  title,
  description,
  url,
  article_time,
  author,
  publisher,
  keyword,
}) => {
  const props = {
    title:
      typeof title !== 'undefined'
        ? title
        : 'Female Daily Editorial - Info Makeup, Skincare, Trend Kecantikan',
    description:
      typeof description !== 'undefined'
        ? description
        : 'Temukan artikel terkini seputar trend kecantikan, makeup, skincare, tutorial, produk dan perawatan tubuh. Menyajikan informasi lengkap untuk para beauty enthusiast.',
    url: typeof url !== 'undefined' ? url : process.env.NEXT_PUBLIC_ROOT_DOMAIN,
    article_time:
      typeof article_time !== 'undefined'
        ? article_time
        : '2020-11-27T11:24:16+00:00',
    author: author || 'Female Daily',
    publisher: publisher || 'Female Daily',
    keyword: keyword || 'trend kecantikan, makeup, skincare, tutorial, produk dan perawatan tubuh'
  }

  return (
    <Head>
      {/* google webmaster tools */}
      <meta
        name="google-site-verification"
        content={process.env.NEXT_PUBLIC_GOOGLE_VERIFICATION}
      />
      <meta />
      {/*nMosYACQvBo2FkwuITM-OLl3DHHHRBmMOC0ni7_oS3s*/}
      {/* bing webmaster */}
      <meta name="msvalidate.01" content="263CE133D7743371621DBFBE790D1722" />
      {/* META DESCRIPTION */}
      <meta name="description" content={props && props.description}></meta>
      <meta name="author" content={props.author} />
			<meta name="publisher" content={props.publisher} />
			<meta name="keywords" content={props.keyword} />
      <link
        rel="alternate"
        hrefLang="en"
        href={process.env.NEXT_PUBLIC_ROOT_DOMAIN}
      />
      <meta name="theme-color" content="#DB284E" />
      <link rel="canonical" href={props && props.url} />
      {/* <title>{props.title}</title> */}
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={props && props.title} />
      <meta property="og:description" content={props && props.description} />
      <meta property="og:url" content={props && props.url} />
      <meta property="og:site_name" content="Female Daily" />
      <meta
        property="article:modified_time"
        content={props && props.article_time}
      />
      <meta name="twitter:card" content="summary" />
      <script
        async
        type="text/javascript"
        src="https://platform.instagram.com/en_US/embeds.js"
        charSet="utf-8"
      ></script>
    </Head>
  )
}

export default MetaHeader
