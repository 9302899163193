import React from 'react'
import Shimmer from 'Components/shimmer'
const ShimmerArticle = () => {
  return (
    <div className="container-articles">
      <div className="title">
        <Shimmer height="15px" width="225px" />
      </div>
      <div className="list-shimmer">
        {[0, 1, 2, 3].map((item) => (
          <div className="card-shimmer" key={item}>
            <Shimmer height="100px" width="100px" />
            <div>
              <Shimmer height="15px" width="125px" />
              <Shimmer height="20px" width="100%" />
              <Shimmer height="20px" width="70%" />
              <Shimmer height="20px" width="50%" />
            </div>
          </div>
        ))}
      </div>
      <style jsx>
        {`
          .container-articles {
            margin: 40px 0;
          }
          .title {
            display: flex;
            justify-content: center;
            margin: 20px 0;
          }
          .card-shimmer {
            display: grid;
            gap: 8px;
            grid-template-columns: 100px auto;
          }
          .list-shimmer {
            row-gap: 10px;
            display: grid;
            padding: 10px;
          }
        `}
      </style>
    </div>
  )
}

export default ShimmerArticle
