import React from 'react'
import Shimmer from 'Components/shimmer'
const ShimmerArticle = () => {
  return (
    <div className="container-shimmer">
      <div className="box">
        <Shimmer height="24px" width="370px" />
        <Shimmer height="24px" width="404px" />
        <Shimmer height="24px" width="380px" />
        <Shimmer height="14px" width="150px" />
        <Shimmer height="16px" width="380px" />
        <Shimmer height="16px" width="404px" />
        <Shimmer height="16px" width="360px" />
        <Shimmer height="20px" width="170px" />
      </div>
      <style jsx>
        {`
          .box {
            width: 526px;
            height: 425px;
            border: 1px solid #e0e0e0;
            margin-right: 134px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            background: #fff;
          }
          .container-shimmer {
            width: 100%;
            height: 500px;
            background: #fafafa;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
        `}
      </style>
    </div>
  )
}

export default ShimmerArticle
