import React from 'react'
import Shimmer from 'Components/shimmer'
const ShimmerArticle = () => {
  return (
    <div className="container-articles">
      <Shimmer height="20px" width="260px" />
    <div className="list-shimmer">
      {[0, 1, 2, 3].map((item) => (
        <div className="card-shimmer" key={item}>
          <Shimmer height="178px" width="270px" />
          <Shimmer height="20px" width="260px" />
          <Shimmer height="20px" width="240px" />
          <Shimmer height="20px" width="160px" />
          <Shimmer height="10px" width="260px" />
        </div>
      ))}
     
    </div>
    <style jsx>
        {`
          .card-shimmer {
          }
          .list-shimmer {
            grid-gap: 40px;
            display: grid;
            grid-template-columns: repeat(4,270px);
            margin: 20px 0;
          }
          .container-articles {
            display: flex;
            flex-flow: column;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin: 50px 0;
          }
        `}
      </style>
    </div>
  )
}

export default ShimmerArticle
