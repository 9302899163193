import { wrapper } from '@/Store/index'
import { END } from 'redux-saga'
import { loadSelf } from '@fdn/profile_store'
import { useSelector } from 'react-redux'
import dynamic from 'next/dynamic'
import Env from 'Consts/env'
import ShimmerBanner from 'Components/shimmer/banner'
import ShimmerArticles from 'Components/shimmer/popular'

const cookie = require('cookie')

import React, { useState, useEffect } from 'react'
import { fetchWithCache } from 'Helpers/fetch'
import { ads_detik } from 'Helpers/utils'
import MetaHeader from 'Containers/meta_header'

const Banner = dynamic(() => import('Components/banner/banner'))

const Layout = dynamic(() => import('Containers/layout'), {
  ssr: true,
})

const TabArticle = dynamic(() => import('Containers/tab_article'))
const CampaignArticle = dynamic(() => import('Containers/campaign_article'))
const LatestArticle = dynamic(() =>
  import('Containers/latest_article/latest_article'),
)

const BeautyFiles = dynamic(() =>
  import('Containers/beauty_files/beauty_files'),
)
const Video = dynamic(() => import('Containers/video/video'))

const Home = (props) => {
  const isMobile = props.is_mobile
  const BannerHome = dynamic(
    () => import('Containers/banner_home/banner_home'),
    {
      ssr: false,
      loading: () => <ShimmerBanner isMobile={isMobile} />,
    },
  )

  const PopularArticle = dynamic(
    () => import('Containers/popular_article/popular_article'),
    { ssr: false, loading: () => <ShimmerArticles isMobile={isMobile} /> },
  )

  const PeopleLifestyleArticle = dynamic(
    () =>
      import('Containers/people_lifestyle_article/people_lifestyle_article'),
    { ssr: false, loading: () => <ShimmerArticles isMobile={isMobile} /> },
  )

  const storeData = useSelector((state) => state)
  const [dataAds, setdataAds] = useState(false)
  const [dataAdsBeauty, setdataAdsBeauty] = useState(false)

  useEffect(() => {
    // banner_container_ragtangle
    setTimeout(() => {
      document.getElementsByClassName('banner_container_ragtangle1').length
        ? document.getElementsByClassName('banner_container_ragtangle1')[0]
          .offsetHeight !== 0
          ? setdataAds(true)
          : null
        : null
    }, 5000)

    setTimeout(() => {
      document.getElementsByClassName('banner_container_ragtangle2').length
        ? document.getElementsByClassName('banner_container_ragtangle2')[0]
          .offsetHeight !== 0
          ? setdataAdsBeauty(true)
          : null
        : null
    }, 5000)
  }, [])

  return (
    <Layout
      home
      page_type="frontpage"
      title={'Info, Makeup, Skincare, Trend'}
      is_mobile={isMobile}
      self={storeData.storeSelf}
      ads_head={props.adsHead}
      ads_foot={props.adsFoot}
    >
      <MetaHeader />
      <main>
        <section>
          <div className="container fluid">
            <div className="row">
              <div className="col p-0">
                {!!isMobile ? (
                  <Banner
                    name="staticbanner1"
                    placeholder="staticbanner1"
                    is_mobile={isMobile}
                    class_name="ads-billboard"
                  />
                ) : (
                  <Banner
                    name="billboard"
                    placeholder="billboard"
                    is_mobile={isMobile}
                    class_name="ads-billboard"
                  />
                )}

                {props.dataArticleFeatured?.data && (
                  <BannerHome
                    is_mobile={isMobile}
                    sponsoredPost={props.dataArticleFeatured?.data}
                  />
                )}
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <PopularArticle
                  is_mobile={isMobile}
                  dataPopular={
                    props.dataPopularArticles && props.dataPopularArticles.data
                  }
                />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <LatestArticle
                  is_mobile={isMobile}
                  dataArticleLatest={
                    props.dataArticleLatest && props.dataArticleLatest.data
                  }
                />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container fluid">
            <div className="row">
              <BeautyFiles
                is_mobile={isMobile}
                dataAds={dataAds}
                beauty_files={
                  props.dataBeautyFiles && props.dataBeautyFiles.data
                }
              />
            </div>
          </div>
        </section>
        {!!isMobile ? (
          <section>
            <Banner
              name="staticbanner2"
              placeholder="staticbanner2"
              is_mobile={isMobile}
              class_name="ads-staticbanner2"
            />
            <style>
              {`
					.ads-staticbanner2{
						text-align: center;
					}
					`}
            </style>
          </section>
        ) : null}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <CampaignArticle
                  is_mobile={isMobile}
                  dataArticleCampaign={props.dataArticleCampaign?.data?.data}
                  borderImage={props.dataArticleCampaign?.data?.border_image}
                  articleTitle={props.dataArticleCampaign?.data?.title_image}
                />
              </div>
            </div>
          </div>
        </section>

        {props && props.dataTabArticle.data && props.dataTabArticle.data.length > 0 && (
          <section>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <TabArticle
                    is_mobile={isMobile}
                    dataAdsBeauty={dataAdsBeauty}
                    tabData={props.dataTabArticle && props.dataTabArticle.data}
                    link_cta={
                      process.env.NEXT_PUBLIC_ROOT_DOMAIN +
                      '/blog/category/beauty-school'
                    }
                  />
                </div>
              </div>
            </div>
          </section>
        )}

        {!!isMobile ? (
          <section>
            <Banner
              name="staticbanner3"
              placeholder="staticbanner2"
              is_mobile={isMobile}
              class_name="ads-staticbanner3"
            />
            <style>
              {`
					.ads-staticbanner3{
						text-align: center;
					}
					`}
            </style>
          </section>
        ) : null}

        {!!props.dataArticleCategoryBundle &&
          props.dataArticleCategoryBundle.data && (
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <PeopleLifestyleArticle
                      title={'People'}
                      is_mobile={isMobile}
                      dataArticle={
                        props.dataArticleCategoryBundle.data.beautiful_people
                      }
                      link_cta={process.env.NEXT_PUBLIC_ROOT_DOMAIN}
                      slug_url={'/blog/category/beautiful-people'}
                      section_name={'people'}
                    />
                  </div>
                </div>
              </div>
            </section>
          )}
        {!!props.dataArticleCategoryBundle &&
          props.dataArticleCategoryBundle.data && (
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <PeopleLifestyleArticle
                      title={'Lifestyle'}
                      is_mobile={isMobile}
                      dataArticle={
                        props.dataArticleCategoryBundle.data.lifestyle
                      }
                      link_cta={process.env.NEXT_PUBLIC_ROOT_DOMAIN}
                      slug_url={'/blog/category/lifestyle'}
                      section_name={'lifestyle'}
                    />
                  </div>
                </div>
              </div>
            </section>
          )}

        <section>
          <div className="container fluid">
            <div className="row">
              <div className="col-12">
                <Video
                  is_mobile={isMobile}
                  dataVideo={props.dataVideo && props.dataVideo.data}
                />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="main-text">
                  Female Daily, kumpulan informasi kecantikan terkini untuk
                  beauty enthusiast Indonesia.
                </p>
                <p className="main-subtext">
                  Temukan informasi terkini seputar trend kecantikan, makeup,
                  skincare, tutorial, produk dan perawatan tubuh untuk para
                  beauty enthusiast.
                </p>
              </div>
            </div>
          </div>
        </section>

        <style>
          {`
            .ads-billboard {
              margin-top: 0;
              align-items: center;
              display: flex;
              justify-content: center;
            }
            .ads-billboard:has(iframe),
            .ads-billdboard:has(a) {
              margin-top: ${isMobile ? '0px' : '66px'};
            }
            .main-text {
              font-family: Josefin Sans, sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
              letter-spacing: -0.05em;
              text-transform: capitalize;
              color: #000000;
              margin: 0 0 8px 0;
            }

            .main-subtext {
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 26px;
              color: #454F5B;
              margin: 0 0 24px 0;
            }

            section {
              margin-bottom: 60px;
            }

            @media only screen and (max-width: 600px) {

              .main-subtext {
                margin: 0 0 16px 0;
              }

              section {
                margin-bottom: 40px;
              }

            }
			`}
        </style>
      </main>
    </Layout>
  )
}

export const getServerSideProps = wrapper.getServerSideProps(
  async ({ store, req, res, query }) => {
    // if (!!query.p) {
    //   res.writeHead(302, {
    //     Location: `/preview/${query.p}`,
    //   })
    //   res.end()
    // }

    let userAgent
    if (req) {
      // if you are on the server and you get a 'req' property from your context
      userAgent = req.headers['user-agent'] // get the user-agent from the headers
    } else {
      userAgent = navigator.userAgent // if you are on the client you can access the navigator from the window object
    }

    let is_mobile =
      !!userAgent &&
      Boolean(
        userAgent.match(
          /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
        ),
      )
    let cookies,
      authorization = null,
      usrname = null

    const isServer = !!req
    if (req.headers.cookie) {
      cookies = cookie.parse(req.headers.cookie)
      authorization = cookies.token
      usrname = cookies.usrname
    }

    if (authorization) {
      await store.dispatch(
        loadSelf({
          payload: {
            api_env: process.env.NEXT_PUBLIC_NODE_ENV,
            env: Env.HEADERS,
            Authorization: authorization,
            is_server: isServer,
            is_allo: process.env.NEXT_PUBLIC_ALLO_STATUS === "ON",
          },
        }),
      )
    }

    store.dispatch(END)
    await store.sagaTask.toPromise()

    let site = is_mobile === true ? 'mobile' : 'desktop'

    let resPopularArticles
    try {
      const popularArticles = await fetchWithCache(
        `${process.env.NEXT_PUBLIC_POPULAR_ARTICLE}?limit=4`,
      )
      resPopularArticles =
        typeof popularArticles !== 'undefined' ? popularArticles : []
    } catch (error) {
      console.log('Error Popular Articles ===>', error)
    }

    let resBeautyFiles
    try {
      const beautyFiles = await fetchWithCache(
        process.env.NEXT_PUBLIC_BEAUTY_FILES,
        24,
      )
      resBeautyFiles = typeof beautyFiles !== 'undefined' ? beautyFiles : []
    } catch (error) {
      console.log('Error Beauty Files ===>', error)
    }

    let resArticleFeatured
    try {
      const articleFeatured = await fetch(
        `${process.env.NEXT_PUBLIC_TAG_BEAUTY}/featured?limit=5`,
        { headers: { ...Env.HEADERS } },
      )
      resArticleFeatured = await articleFeatured.json()
    } catch (error) {
      console.log('Error Article Featured ===>', error)
    }

    let resArticleCategoryBundle
    try {
      const articleCategoryBundle = await fetch(
        `${process.env.NEXT_PUBLIC_ARTICLE_CATEGORIES}?slugs=lifestyle,beautiful_people&limit=5`,
        { headers: { ...Env.HEADERS } },
      )
      resArticleCategoryBundle = await articleCategoryBundle.json()
    } catch (error) {
      console.log('Error Article Category Bundle ===>', error)
    }

    let resVideo
    try {
      const video = await fetchWithCache(
        `${process.env.NEXT_PUBLIC_DISCOVER_VIDEO}?limit=5`,
      )
      resVideo = typeof video !== 'undefined' ? video : []
    } catch (error) {
      console.log('Error Video ===>', error)
    }

    let resArticleLatest
    try {
      const articleLatest = await fetch(
        `${process.env.NEXT_PUBLIC_ARTICLE}?limit=5`,
        { headers: { ...Env.HEADERS } },
      )
      resArticleLatest = await articleLatest.json()
    } catch (error) {
      console.log('Error Article Latest ===>', error)
    }

    let resArticleCampaign
    try {
      const articleCampaign = await fetchWithCache(
        `${process.env.NEXT_PUBLIC_CAMPAIGN}?limit=5`,
      )
      resArticleCampaign =
        typeof articleCampaign !== 'undefined' ? articleCampaign : []
    } catch (error) {
      console.log('Error Article Campaign ===>', error)
    }

    let resTabArticle
    try {
      const tabArticle = await fetchWithCache(
        process.env.NEXT_PUBLIC_ARTICLE_BEAUTY,
        24,
      )
      resTabArticle = typeof tabArticle !== 'undefined' ? tabArticle : []
    } catch (error) {
      console.log('Error Tab Article ===>', error)
    }

    const adsHead = await ads_detik(site, 'taghead')
    const adsFoot = await ads_detik(site, 'tagfoot')
    return {
      props: {
        is_mobile,
        dataVideo: resVideo || null,
        dataBeautyFiles: resBeautyFiles || null,
        dataPopularArticles: resPopularArticles || null,
        dataArticleCategoryBundle: resArticleCategoryBundle || null,
        dataArticleLatest: resArticleLatest || null,
        dataArticleCampaign: resArticleCampaign || null,
        dataTabArticle: resTabArticle || null,
        dataArticleFeatured: resArticleFeatured || null,
        adsHead,
        adsFoot,
      },
      // Next.js will attempt to re-generate the page:
      // - When a request comes in
      // - At most once every 10 seconds
      // revalidate: 30, // In seconds
    }
  },
)

export default Home
