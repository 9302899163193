import React from 'react'
import Shimmer from 'Components/shimmer'
const ShimmerArticle = () => {
  return (
    <div className="list-shimmer">
      <Shimmer height="200px" width="450px" />
      <div className="desc">
        <Shimmer height="30px" width="135px" />
        <Shimmer height="20px" width="250px" />
        <Shimmer height="20px" width="250px" />
        <Shimmer height="20px" width="100px" />
        <Shimmer height="15px" width="150px" />
      </div>

      <style jsx>
        {`
          .desc {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column;
            border: 1px solid #e0e0e0e0;
            width: calc(100% - 20px);
          }
          .list-shimmer {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
          }
        `}
      </style>
    </div>
  )
}

export default ShimmerArticle
